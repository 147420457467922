import { BrowserRouter, Routes, Route } from "react-router-dom"
import Form from "./containers/Form"
import SimpleForm from "./containers/SimpleForm"
import Success from "./containers/Success"

import "./App.css"

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Form />} />
				<Route path="/pro" element={<SimpleForm />} />
				<Route path="/success" element={<Success />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
