import React from "react"
import { Link } from "react-router-dom"

import orthonovlogo from "../img/orthonovlogo.png"

const Footer = ({ route, text }) => (
	<div className="footer">
		<img className="img-logo-footer" src={orthonovlogo} alt="orthonov-logo"></img>
		<a href="https://www.orthonov.com/" target="_blank" rel="noreferrer noopener">
			<div className="title-footer-orthonov">ORTHONOV SAS</div>
		</a>
		<div className="texte-footer">3, rue de Villebon - 91160 Saulx-les-Chartreux</div>
		<div className="texte-footer">Tél : 01 60 13 65 73</div>
		<div className="space"></div>

		<Link to={route} className="texte-footer">
			{text}
		</Link>
		<a href="https://www.orthonov.com/mentions-legales/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Mentions Légales</div>
		</a>
		<a href="https://orthonov.com/conditions-generales-de-vente/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Conditions Générales de Vente</div>
		</a>
		<a href="https://www.orthonov.com/notre-entreprise/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Notre entreprise</div>
		</a>
		<a href="https://www.orthonov.com/prise-en-charge/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Prise en charge</div>
		</a>
		<a href="https://www.orthonov.com/politique-de-confidentialite/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Politique de confidentialité</div>
		</a>
		<a href="https://www.orthonov.com/politique-de-confidentialite/" target="_blank" rel="noreferrer noopener">
			<div className="texte-footer">Confidentialité des données</div>
		</a>

		<div className="space"></div>

		<div className="texte-footer-copyright">©2022 SAS ORTHONOV - Tous droits réservés.</div>
	</div>
)

const Header = () => (
	<div className="header">
		<img className="img-logo" src={orthonovlogo} alt="orthonov-logo"></img>
	</div>
)

export { Header, Footer }
