import React from "react"
import "../Themes.css"

import { Header, Footer } from "../../components/Sections"
import { MapsContainer, AcceptContainer } from "../../components/RHFComponents/live_components"
import onSubmitFactory from "./onSubmit"
import {
	InputFactory,
	InputFileFactory,
	GoogleAutoCompleteFactory,
	SelectAutoCompleteFactory,
	SSNControllerFactory,
	PhoneControllerFactory,
} from "../../components/RHFComponents"
import { filterPrescribers } from "../../services/api"

import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"

import schema from "../../schemas/record"
import { joiResolver } from "@hookform/resolvers/joi"

export default function Form() {
	const navigate = useNavigate()
	const {
		control,
		register,
		handleSubmit,
		setError,
		watch,
		formState: { errors },
	} = useForm({
		resolver: joiResolver(schema) /* mode: "onBlur" */,
	}) // to validate each time we leave a field.
	const Input = InputFactory(register, errors)
	const InputFile = InputFileFactory(register, control, errors)
	const GoogleAutoComplete = GoogleAutoCompleteFactory(control)
	const SelectAutoComplete = SelectAutoCompleteFactory(control)
	const SSNInput = SSNControllerFactory(control)
	const PhoneInput = PhoneControllerFactory(control)
	const onSubmit = onSubmitFactory(navigate, setError)
	const { toggle } = watch()

	return (
		<div>
			<Header />
			<div className="title-formula">Formulaire de commande</div>
			<div className="under-title-formula">
				Commandez votre attelle prise en charge par la Sécurité Sociale et votre mutuelle en remplissant le formulaire
				ci-dessous.
			</div>

			<form className="form-big" onSubmit={handleSubmit(onSubmit)}>
				{Input("text", "firstname", "Prénom")}
				{Input("text", "lastname", "Nom")}
				{Input("date", "birth", "Date de Naissance", "Clicker sur la flèche pour ouvrir le calendrier")}
				{Input("email", "email", "Email")}
				{PhoneInput("phone", "Téléphone portable")}
				{GoogleAutoComplete(
					"address",
					"Adresse de facturation",
					"Si votre adresse est introuvable, contactez-nous directement par téléphone au 01 60 13 65 73"
				)}
				<div
					className="text_toggle"
					style={{
						display: toggle ? "none" : "block", // toggle the visbility of an input
					}}
				>
					Votre adresse de livraison est-elle différente ?
					<input type="checkbox" name="toggle" {...register("toggle")} />
				</div>
				{toggle &&
					GoogleAutoComplete(
						"shipping_address",
						"Adresse de livraison",
						"Si votre adresse est introuvable, contactez-nous directement par téléphone au 03 21 09 25 96"
					)}
				<div className="arange-map">
					<MapsContainer control={control} />
				</div>
				{SSNInput("social_security", "Numéro de Sécurité Sociale")}
				{InputFile(
					"mutual_photo",
					"Vous pouvez prendre en photo et/ou deposer votre carte mutuelle, avec le nom de la mutuelle et le numéro AMC (ou le numéro de télétransmission) bien visible",
					"Photo de la carte mutuelle"
				)}
				{Input(
					"number",
					"amc",
					"Numéro AMC ou de télétransmission",
					"Le numéro AMC parfois appelé numéro de télétransmission est le numéro d’identification de votre mutuelle. Vous pouvez l'obtenir sur votre carte de mutuelle et sur votre compte Ameli.fr"
				)}
				{Input(
					"text",
					"mutual",
					"Mutuelle",
					"Le nom de votre Mutuelle Santé est située sur votre carte de mutuelle et sur votre compte Ameli.fr dans la rubrique 'complémentaire santé'"
				)}
				{SelectAutoComplete(
					"prescriber",
					filterPrescribers,
					"Prescripteur",
					"Ici vous rentrez le nom du médecin ayant délivré votre ordonnance "
				)}
				{Input(
					"date",
					"surgery_date",
					"En cas d’intervention, précisez la date",
					"Clicker sur la flèche pour ouvrir le calendrier"
				)}
				{InputFile("prescription", "Vous pouvez prendre en photo et/ou deposer votre ordonnance", "Ordonnance")}
				<div className="accept-bulletin-flex">
					{Input("checkbox", "accept", "", "", "", "", "", "error")}
					<AcceptContainer control={control} />
				</div>
				<input className="button-submit-patient" type="submit" value="Envoyer" />
				<div className="final-error"> {Object.keys(errors).length > 0 && "Vérifiez les champs requis"}</div>
			</form>
			<Footer route="/pro" text="Espace Professionel" />
		</div>
	)
}
