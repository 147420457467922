import React from "react"
import { Controller } from "react-hook-form"

import NumberFormat from "react-number-format"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import AsyncSelect from "react-select/async"
import { components } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import Tooltip from "./Tooltip"

const controlStylesHasError = (hasError) =>
	hasError
		? { borderColor: "red", width: "285px", marginRight: "7px", padding: 0 }
		: { width: "285px", padding: 0, marginRight: "7px" }
const customStyles = (invalid) => ({
	control: (base) => ({ ...base, ...controlStylesHasError(invalid) }),
	singleValue: (base) => ({ ...base, ...{ color: "rgb(145, 145, 145)" } }),
	/* placeholder: (base) => ({ ...base, fontSize: "1em", color: "blue", fontWeight: 400 }), */
})
const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<FontAwesomeIcon icon={faSearch} />
		</components.DropdownIndicator>
	)
}
const Placeholder = (props) => {
	return <components.Placeholder {...props} />
}

const GoogleAutoCompleteFactory = (control) => (name, placeholder, tip) =>
	(
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field, fieldState: { invalid, error } }) => (
				<div className="input-container">
					<div className="flex">
						<GooglePlacesAutocomplete
							selectProps={{
								...field,
								isClearable: true,
								styles: customStyles(invalid),
								components: { Placeholder, DropdownIndicator },
								placeholder: error ? error.message : placeholder,
								noOptionsMessage: () => "Recherche en cours...",
							}}
						/>
						<Tooltip tip={tip} name={name} />
					</div>
					{error?.message}
				</div>
			)}
		/>
	)

const SelectAutoCompleteFactory = (control) => (name, promiseOptions, placeholder, tip) =>
	(
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field, fieldState: { invalid, error } }) => {
				return (
					<div className="input-container">
						<div className="flex">
							<AsyncSelect
								{...field}
								isClearable={true}
								cacheOptions
								styles={customStyles(invalid)}
								components={{ Placeholder, DropdownIndicator }}
								placeholder={placeholder}
								noOptionsMessage={() => "Recherche en cours..."}
								loadOptions={promiseOptions}
							/>
							<Tooltip tip={tip} name={name} />
						</div>
						{error?.message}
					</div>
				)
			}}
		/>
	)

const SSNControllerFactory = (control) => (name, placeholder, tip) =>
	(
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field: { onChange, value }, fieldState: { invalid, error } }) => {
				return (
					<div className="input-container">
						<div className="flex">
							<NumberFormat
								placeholder={placeholder}
								type="tel"
								format="# ## ## ## ### ### ##"
								mask="X"
								onValueChange={(v) => {
									onChange(v.value)
								}}
								value={value}
								className={error ? "input-field errorClass" : "input-field"}
								isNumericString={true}
							/>
							<Tooltip tip={tip} name={name} />
						</div>
						{error?.message}
					</div>
				)
			}}
		/>
	)

const PhoneControllerFactory = (control) => (name, placeholder, tip) =>
	(
		<Controller
			name={name}
			control={control}
			defaultValue={null}
			render={({ field: { onChange, value }, fieldState: { invalid, error } }) => {
				return (
					<div className="input-container">
						<div className="flex">
							<NumberFormat
								placeholder={placeholder}
								type="tel"
								format="## ## ## ## ##"
								mask="X"
								onValueChange={(v) => {
									onChange(v.value)
								}}
								value={value}
								className={error ? "input-field errorClass" : "input-field"}
								isNumericString={true}
							/>
							<Tooltip tip={tip} name={name} />
						</div>
						{error?.message}
					</div>
				)
			}}
		/>
	)

export { GoogleAutoCompleteFactory, SelectAutoCompleteFactory, SSNControllerFactory, PhoneControllerFactory }
