import Joi from "joi"

export default Joi.object({
	social_security: Joi.string()
		.pattern(
			/^[1-478][0-9]{2}(0[1-9]|1[0-2]|62|63)(2[ABab]|[0-9]{2})(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/i
		)
		.required()
		.messages({
			"string.base": `Le numéro de sécurité social n'a pas le bon format`,
			"string.empty": `Saisissez votre numéro de sécurité social`,
			"string.pattern.base": `Le numéro de sécurité social n'a pas le bon format`,
			"any.required": "Saisissez votre numéro de sécurité social",
		}),

	prescription: Joi.any()
		.custom((value, helpers) => {
			if (value.length === 0) {
				throw new Error("Il manque l'ordonnance")
			}
			return value
		})
		.messages({
			"any.custom": `Il manque l'ordonnance`,
		}),
})
