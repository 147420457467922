import { formatAddress } from "../../services/googleApi"
import { addRecord } from "../../services/api"

const onSubmitFactory = (navigate, setError) => async (data) => {
	try {
		const [address, city, postalcode] = await formatAddress(data.address)
		if (address === "" || city === "" || postalcode === "") {
			throw new Error("address")
		}
		const [shipping_address, shipping_city, shipping_postalcode] = data.shipping_address
			? await formatAddress(data.shipping_address)
			: [address, city, postalcode]
		if (shipping_address === "" || shipping_city === "" || shipping_postalcode === "") {
			throw new Error("shipping_address")
		}
		const formData = new FormData()
		formData.append("firstname", data.firstname)
		formData.append("lastname", data.lastname)
		formData.append("birth", data.birth)
		formData.append("email", data.email)
		formData.append("phone", data.phone)
		formData.append("address", address)
		formData.append("city", city)
		formData.append("postalcode", postalcode)
		formData.append("shipping_address", shipping_address)
		formData.append("shipping_city", shipping_city)
		formData.append("shipping_postalcode", shipping_postalcode)
		formData.append("social_security", data.social_security)
		formData.append("amc", data.amc)
		formData.append("mutual", data.mutual)
		formData.append("prescriber", data.prescriber._id)
		formData.append("accept", data.accept)
		data.mutual_photo[0] && formData.append("mutual_photo", data.mutual_photo[0])
		data.prescription[0] && formData.append("prescription", data.prescription[0])
		data.surgery_date && formData.append("surgery_date", data.surgery_date)
		await addRecord(formData)
		navigate("/success")
	} catch (err) {
		if (err.response && err.response.data && err.response.data.cause === "Prescription is missing") {
			setError("prescription", { type: "custom", message: "Veuillez joindre l'ordonnance" })
		} else if (err.message === "address") {
			setError("address", { type: "custom", message: "On attend une adresse postale" })
		} else if (err.message === "shipping_address") {
			setError("shipping_address", { type: "custom", message: "On attend une adresse postale" })
		} else {
			alert(
				"La commande a échoué pour des raisons inconnues. Veuillez contacter le support Orthonov par mail à contact@orthonov.com ou par téléphone au 01 60 13 65 73"
			)
		}
	}
}

export default onSubmitFactory
