import { addPrescription } from "../../services/api"

const onSubmitFactory = (navigate) => async (data) => {
	try {
		const formData = new FormData()
		//formData.append("email", data.email)
		formData.append("social_security", data.social_security)
		formData.append("prescription", data.prescription[0])
		await addPrescription(formData)
		navigate("/success")
	} catch (err) {
		alert("La commande a échoué pour des raisons inconnues")
	}
}

export default onSubmitFactory
