import React from "react"
import "../Themes.css"

import { Header, Footer } from "../../components/Sections"
import onSubmitFactory from "./onSubmit"
import { InputFileFactory, SSNControllerFactory } from "../../components/RHFComponents"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"

import schema from "../../schemas/prescription"
import { joiResolver } from "@hookform/resolvers/joi"

export default function Form() {
	const navigate = useNavigate()
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: joiResolver(schema) /* mode: "onBlur" */ }) // to validate each time we leave a field.

	const InputFile = InputFileFactory(register, control, errors)
	const SSNInput = SSNControllerFactory(control)

	const onSubmit = onSubmitFactory(navigate)

	return (
		<div>
			<Header />
			<div className="title-formula">Formulaire du Patient</div>
			<div className="under-title-formula">À remplir par l'équipe administrative et médicale</div>

			<form className="form-big" onSubmit={handleSubmit(onSubmit)}>
				{SSNInput("social_security", "Numéro de Sécurité Sociale")}
				{InputFile("prescription", "Vous devez prendre en photo et/ou deposer votre ordonnance", "Ordonnance")}

				<input className="button-submit-secrétaire" type="submit" value="Envoyer" />
				<div className="final-error"> {Object.keys(errors).length > 0 && "Vérifiez les champs requis"}</div>
			</form>

			<Footer route="/" text="Espace Patient" />
		</div>
	)
}
